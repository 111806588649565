// Here you can add other styles
.react-datepicker-wrapper{
  width:100%;
  margin-left: -14px;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
  top: 0;
  margin-top: -8px;
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
  margin-left: -11px;
  position: absolute;
  width: 0;
}
.datepicker_position{
  height: 32px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;
}

.react-datepicker-ignore-onclickoutside{
  height:35px;
}

.loading-spinner {
  border: 7px solid #f3f3f3;
  border-top: 7px solid #f7ae65;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}



// Add a custom color to $theme-colors
$custom-colors: (
  "custom-color": #712cf9
);





// etc
